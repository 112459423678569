//专门的路由配置文件
export default[
    {
        //首页
        path:'/',//在8080基础上的路径
        name:'Layout',
        component:()=>import("../views/Layout"),//后缀可以省略文件地址
    },
    {
        //登录页面
        path:'/Login',
        name:'Login',
        component:()=>import("../views/Login")
    },
    {
        //404页面放在最后在前面所以页面无法进入时才会执行
        path:'*',
        name:'404',
        component:()=>import("../views/404")
    }
]
