import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    row:{}
  },
  mutations: {
    //方法存储数据
    setRow(state,payload){
      state.row=payload
    }  
  },
  actions: {
  },
  modules: {
  }
})
