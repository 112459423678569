//导航守卫，登录跳转之后阻止返回登录页
import router from "./index";
import {getToken} from "../utils/auth"
router.beforeEach((to,from,next)=>{
    const hasToken=getToken();
    if(hasToken){//登录之后
        if(to.path==='/login'){//判断去的地址
            next("/")
        }else{
            next()//放行
        } 
    }else{
        if(to.path==='/login'){
            next()
        }else{
            next("/login")
        }  
    }
})