//权限文件，封装存储token的方法
//存储
export function setToken(token){
    sessionStorage.setItem("token",token)//页面关闭值会消失
}
//取值
export function getToken(){
    return sessionStorage.getItem("token")//注意return
}
//删除（退出登录需要删除token）
export function removeToken(){
    sessionStorage.removeItem("token")
}